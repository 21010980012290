import {
  FeedbackItem,
  FeedbackItemSettings,
} from '@wix/ambassador-challenges-v1-participant/types';

export enum IQuestionnaireType {
  CLASSIC = 'CLASSIC',
  QUIZ = 'QUIZ',
}

export interface IQuestionnaireSend {
  [id: string]: {
    type: string;
    value: any;
  };
}

export interface IQuestionnaireProps {
  t(key: string, params?: any): string;
  isMobile: boolean;
  className?: string;
  dataHook?: string;
  quizCheckSnapshot(stepId: string, questionsJson: any): void;

  stepId: string;
  stepName: string;
  stepDate: string;
  isStepResolved: boolean;

  type: IQuestionnaireType;
  hasNextStep?: boolean;

  feedbackItems: FeedbackItem[];
  feedbackSettings: FeedbackItemSettings[];

  onSend(data: IQuestionnaireSend): void;
  onCancel?(): void;
}

export interface IQuestionnaireDefaultProps {
  onCancel(): void;
}

export interface IQuestionnaireState {
  filledQuestionsCount: number;
  totalQuestionsCount: number;
  isValid: boolean;
  isMediaLoading: boolean;
  fields: {
    [field: string]: any;
  };
  mainButtonState: 'Disabled' | 'Check' | 'Send';
  cancelButtonState: 'Disabled' | 'Skip' | 'Cancel';
  isCheckAnswersState: boolean;
}
