export const EXPERIMENTS_SCOPE = 'challenges';
export const ARTIFACT_VERSION = process.env.ARTIFACT_VERSION;
export enum VIEW_MODE {
  Editor = 'Editor',
  Preview = 'Preview',
  Site = 'Site',
}

export enum APPS {
  Prod = '2936472a-a1ed-4ae5-9f71-614313a9f4e7',
  Dev = 'd03eaae3-1bef-4ddc-9631-27d5fa0bcedc',
}

interface IAppConfig {
  widgetId: string;
  pageId: string;
  paymentPageId: string;
  widgetType: string;
  pageType: string;
  pageSlug: string;
}

const prodAppConfig: IAppConfig = {
  widgetId: 'achievements_dev',
  pageId: 'challenge_page',
  paymentPageId: 'Challenge Payment',
  pageSlug: 'challenge-page',
  widgetType: 'ef4e59bd-625a-484e-9ba1-650d4aecf03b',
  pageType: '847c2f5d-017f-499f-9c3e-069164fc116f',
};

const devAppConfig: IAppConfig = {
  widgetId: 'challenges_dev_widget',
  pageId: 'challenges_dev_page',
  paymentPageId: 'Challenge Payment',
  pageSlug: 'challenges-dev-page',
  widgetType: '4224a7c4-3783-466b-be3e-b0826d2225da',
  pageType: '642217d5-f1e7-476a-8c55-7241da8e2267',
};

export const APP_CONFIG: { [key: string]: IAppConfig } = {
  [APPS.Prod]: prodAppConfig,
  [APPS.Dev]: devAppConfig,
};
