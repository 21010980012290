import React from 'react';
import { ListChallengesResponse } from '@wix/ambassador-challenges-v1-challenge/types';

export interface IChallengesListDataContext {
  challengesListData: ListChallengesResponse;
}

export const ChallengesListDataContext = React.createContext<IChallengesListDataContext>(
  {
    challengesListData: null,
  },
);

export const ChallengesListDataConsumer = ChallengesListDataContext.Consumer;
