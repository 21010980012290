import React from 'react';
import { contextFactory } from '../../services/contextFactory';
import { IBadgesContext, IBadgesProviderProps } from './interfaces';

export const { context, ContextProvider, withConsumer } = contextFactory<
  IBadgesContext,
  IBadgesProviderProps
>({
  defaultValues: {
    requestBadges: null,
    badges: [],
  },
  displayName: 'BadgesProvider',
  propsToContext(props): IBadgesContext {
    return {
      ...props,
    };
  },
});

export const BadgesContext = context;
export const BadgesProvider = ContextProvider;
export const useBadges = () => React.useContext(BadgesContext);
