import React from 'react';
import { ISettingsEvents } from '../../components/ChallengesPage/Widget/settingsEvents';

export interface ISettingsEventsContext extends ISettingsEvents {
  shownTab: ISettingsEvents['listLayoutSelectedTab'];
}

export const SettingsEventsContext =
  React.createContext<ISettingsEventsContext>({
    buttonState: null,
    categoriesTabsState: null,
    pageRole: null,
    listLayoutSelectedTab: null,
    shownTab: null,
    sidebarLayoutMobileScreen: null,
  });

export const useSettingsEvent = (): ISettingsEventsContext => {
  return React.useContext(SettingsEventsContext);
};

export const SettingsEventsConsumer = SettingsEventsContext.Consumer;

export const SettingsEventsProvider: React.FC<ISettingsEventsContext> = (
  props,
) => {
  return (
    <SettingsEventsContext.Provider
      value={{
        buttonState: props.buttonState,
        pageRole: props.pageRole,
        listLayoutSelectedTab: props.listLayoutSelectedTab,
        shownTab: props.shownTab,
        categoriesTabsState: props.categoriesTabsState,
        sidebarLayoutMobileScreen: props.sidebarLayoutMobileScreen,
      }}
    >
      {props.children}
    </SettingsEventsContext.Provider>
  );
};

export function withSettingsEvents<Props>(Component: any) {
  return (props) => {
    return (
      <SettingsEventsConsumer>
        {(value) => {
          return <Component {...(props as Props)} {...value} />;
        }}
      </SettingsEventsConsumer>
    );
  };
}

export const useSettingsEvents = () => React.useContext(SettingsEventsContext);
