import React from 'react';
import {
  IParticipantSectionsContext,
  ParticipantSectionsContext,
} from './ParticipantSectionsContext';

export const ParticipantSectionsProvider: React.FC<IParticipantSectionsContext> = (
  props,
) => {
  return (
    <ParticipantSectionsContext.Provider
      value={{
        selectedSection: props.selectedSection,
        isListParticipantSectionsRequestInProgress:
          props.isListParticipantSectionsRequestInProgress,
        listParticipantSections: props.listParticipantSections,
        participantSteps: props.participantSteps,
        updateParticipantSections: props.updateParticipantSections,
      }}
    >
      {props.children}
    </ParticipantSectionsContext.Provider>
  );
};
